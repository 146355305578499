import { Icon, IconProps } from '@chakra-ui/react'

export const EarnIcon = (props: IconProps) => (
  <Icon
    id="icon-earn"
    aria-label="Earn Icon"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    stroke="currentColor"
    viewBox="0 0 45 32"
    strokeWidth="0"
    {...props}
  >
    <path d="M45.176 32h-45.176v-24.473h45.176zM1.883 30.117h41.41v-20.707h-41.41zM37.649 28.234h-30.117v-0.939c0-0 0-0 0-0 0-1.040-0.843-1.883-1.883-1.883-0.008 0-0.015 0-0.023 0h-0.921v-11.276h0.923c0.049 0.005 0.106 0.008 0.164 0.008 0.954 0 1.727-0.773 1.727-1.727 0-0.056-0.003-0.111-0.008-0.166l0.001 0.007v-0.966h30.139v0.944c-0.006 0.056-0.010 0.121-0.010 0.186 0 0.951 0.771 1.721 1.721 1.721 0.059 0 0.117-0.003 0.174-0.009l-0.007 0.001h0.944v11.276h-0.944c0 0 0 0 0 0-1.037 0-1.878 0.841-1.878 1.878 0 0.002 0 0.004 0 0.006v-0zM9.296 26.351h26.588c0.354-1.323 1.375-2.343 2.671-2.692l0.026-0.006v-7.748c-1.391-0.265-2.47-1.341-2.737-2.708l-0.004-0.022h-26.524c-0.267 1.387-1.343 2.463-2.709 2.727l-0.021 0.003v7.742c1.328 0.352 2.354 1.376 2.702 2.677l0.006 0.026zM22.59 24.463c-0.002 0-0.003 0-0.005 0-2.601 0-4.71-2.109-4.71-4.71s2.109-4.71 4.71-4.71c2.601 0 4.71 2.109 4.71 4.71 0 0.002 0 0.004 0 0.006v-0c-0.006 2.596-2.109 4.699-4.704 4.705h-0.001zM22.59 16.909c-0.002 0-0.003 0-0.005 0-1.561 0-2.827 1.266-2.827 2.827s1.266 2.827 2.827 2.827c1.561 0 2.827-1.266 2.827-2.827v0c-0.015-1.549-1.271-2.8-2.821-2.806h-0.001zM32.939 20.686h-1.878v-1.861h1.878zM14.12 20.686h-1.883v-1.861h1.883zM41.41 5.633h-37.644v-1.867h37.644zM37.633 1.856h-30.101v-1.856h30.117z" />
  </Icon>
)
