import { FC, useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalHeader,
  Text,
  Input,
  FormErrorMessage,
  ModalCloseButton,
  ModalBody,
  Button,
  Divider,
  Box,
  VStack,
  FormControl,
  FormLabel,
  Link,
  ModalOverlay,
} from '@chakra-ui/react'
import atgAxiosInstance from 'helpers/axios-atg-helper'
import { useFormat } from 'helpers/hooks/useFormat'
import { utagLink } from 'helpers/tealiumHelper'
import { useForm } from 'react-hook-form'
import SignUpModal from 'frontastic/tastics/SignupModal'
import AtgForgotPassword from './atg-forgot-password'
import GoogleSignInSignUp from './google-signin-signup'

interface Props {
  isOpen: boolean
  onClose: (obj) => void
  toggleForgotPassword: () => void
  callBack?: any
}

const AtgLoginPage: FC<Props> = ({ isOpen, onClose, toggleForgotPassword, callBack }) => {
  const [htmlErrorContent, setErrorHtmlContent] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const fmText = useFormat({ name: 'common' })
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [values, setValues] = useState({
    email: '',
    password: '',
  })

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  })
  const { handleSubmit, register, formState } = useForm()

  const onSubmit = async (data) => {
    if (validateValues()) {
      setIsLoading(true)
      setErrorHtmlContent(null)
      try {
        const res = await atgAxiosInstance.post('currentUser/dxlUserLogin?pushSite=DXL', {
          login: values.email,
          password: values.password,
        })
        setIsLoading(false)
        utagLink([
          'link',
          {
            tealium_event: 'signin',
            signin_source: 'site',
          },
        ])
        window.dispatchEvent(new Event('userLoggedIn'))
        if (callBack) {
          callBack(res)
        }
        onClose({})
      } catch (e) {
        console.log(e)
        setIsLoading(false)
        setErrorHtmlContent(e.response?.data?.title || e.message)
        setTimeout(() => {
          let elem: any = document.querySelector('span[style*="background-color: rgb(255, 255, 255);"]')
          if (elem) {
            elem.style.backgroundColor = '#f8d7da'
          }
        }, 50)
      }
    }
  }

  const validateValues = () => {
    if (values.email.length && values.password.length) {
      if (validateEmail(values.email) && values.password.length > 0) {
        return true
      }
      if (values?.password.length == 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: prevErrors.email,
          password: fmText.formatMessage({ id: 'error.sign.passwordRequired' }),
        }))
        return false
      }

      if (!validateEmail(values.email) && values.password.length <= 1) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: fmText.formatMessage({ id: 'error.sign.invalidEmailText' }),
          password: fmText.formatMessage({ id: 'error.sign.passwordMinRequired' }),
        }))
        return false
      }
      if (!validateEmail(values.email) && values.email.length == 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: fmText.formatMessage({ id: 'error.sign.emailRequired' }),
          password: prevErrors.password,
        }))
        return false
      }
      if (!validateEmail(values.email)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: fmText.formatMessage({ id: 'error.sign.invalidEmailText' }),
          password: prevErrors.password,
        }))
        return false
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: values.email.length == 0 ? 'Email Address is required' : prevErrors.email,
        password: values.password.length == 0 ? 'Password is required' : prevErrors.password,
      }))
      return false
    }
    return false
  }

  const handleGoogleSignInSignup = async (response) => {
    try {
      const res = await atgAxiosInstance.post('currentUser/googleOneTapSignIn', {
        credential: response.credential,
        src: 'login',
      })
      utagLink([
        'link',
        {
          tealium_event: 'signin',
          signin_source: 'google',
        },
      ])
      window.dispatchEvent(new Event('userLoggedIn'))
      if (callBack) {
        callBack(res)
      }
      onClose({})
    } catch (e) {
      console.log('google signin failed', e)
    }
  }

  const closeFPModal = () => {
    setShowForgotPasswordModal(false)
  }

  if (showForgotPasswordModal) {
    return <AtgForgotPassword isOpen={showForgotPasswordModal} onClose={closeFPModal} />
  }
  const closeRegisterModal = () => {
    setShowRegisterModal(false)
  }

  const openLoginModal = () => {
    setIsModalOpen(true)
  }

  if (showRegisterModal) {
    return (
      <SignUpModal
        isOpen={showRegisterModal}
        onClose={closeRegisterModal}
        openSignInModal={openLoginModal}
        callBack={callBack}
      />
    )
  }

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    const trimmedValue = value.replace(/\s/g, '')
    const updatedValues = {
      ...values,
      [name]: trimmedValue,
    }
    setValues(updatedValues)

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]:
        name === 'email' && (value.trim() === '' || !validateEmail(value))
          ? fmText.formatMessage({ id: 'error.sign.invalidEmailText' })
          : '',
    }))
  }

  const handleBlur = (event) => {
    const { name, value } = event.target
    const trimmedValue = value.trim()
    const updatedValues = {
      ...values,
      [name]: trimmedValue,
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]:
        name === 'email' && (value.trim() === '' || !validateEmail(value))
          ? fmText.formatMessage({ id: 'error.sign.invalidEmailText' })
          : prevErrors[name],
    }))
  }
  const onKeyDown = (event) => {
    if (event.code === 'Space') event.preventDefault()
  }

  const resetSignInForm = () => {
    setValues({
      email: '',
      password: '',
    })
  }

  const closeAndReset = () => {
    setErrorHtmlContent(null)
    resetSignInForm()
    onClose({ close: 'byClick' })
  }

  const toggleForgotPasswordFN = () => {
    toggleForgotPassword()
    resetSignInForm()
  }

  const setShowRegisterModalFN = (val) => {
    setShowRegisterModal(val)
    resetSignInForm()
  }

  const isFormInvalid = () => {
    let isInvalid = true
    if (values.email && values.password.length > 0) {
      isInvalid = false
    }
    if (!!errors.email || !!errors.password) {
      isInvalid = true
    }
    return isInvalid
  }

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeAndReset}>
      <ModalOverlay />
      <ModalContent mx={2} my={4} borderRadius="4px" borderColor="black" width={'34.5rem !important'}>
        <ModalHeader p={'20px 34.132px'} fontSize="1.25rem" fontWeight="400" lineHeight="1.2" textAlign="center">
          {fmText.formatMessage({ id: 'text.signin.label.heading' })}
        </ModalHeader>
        <ModalCloseButton size={'lg'} _hover={{ backgroundColor: 'none !important', opacity: '0.7' }} />
        <Box p={'0px 34.132px'}>
          <Divider borderColor="gray.800" />
        </Box>
        <ModalBody p={'10px 34.132px'}>
          {htmlErrorContent && (
            <Box
              p={4}
              color="#721c24"
              textColor="red"
              bgColor="#f8d7da"
              borderColor="#f5c6cb"
              dangerouslySetInnerHTML={{ __html: htmlErrorContent }}
            />
          )}
          <Box paddingTop={3} paddingBottom={6}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing="4" align="stretch">
                <FormControl mb={3} variant={'floating'} isInvalid={!!errors?.email}>
                  <Input
                    onKeyDown={onKeyDown}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    border="1px"
                    borderRadius="1"
                    placeholder=" "
                    name="email"
                    type="email"
                    value={values.email}
                    height={'45px'}
                    color={'text.muted'}
                  />
                  <FormLabel fontSize=".875rem" color={Boolean(errors.email) ? 'text.danger' : 'text.muted'}>
                    {fmText.formatMessage({ id: 'text.signin.label.email' })}
                  </FormLabel>
                  <FormErrorMessage fontSize="smaller">{errors?.email}</FormErrorMessage>
                </FormControl>

                <FormControl variant={'floating'} isInvalid={!!errors?.password} marginBottom={4}>
                  <Input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    border="1px"
                    borderRadius="1"
                    placeholder=" "
                    name="password"
                    type="password"
                    value={values.password}
                    height={'45px'}
                    color={'text.muted'}
                  />
                  <FormLabel fontSize=".875rem" color={Boolean(errors?.password) ? 'text.danger' : 'text.muted'}>
                    {fmText.formatMessage({ id: 'text.signin.label.password' })}
                  </FormLabel>
                  <FormErrorMessage fontSize="smaller">{errors?.password}</FormErrorMessage>
                </FormControl>
                <Box>
                  <Link
                    onClick={toggleForgotPasswordFN}
                    fontSize=".8125rem"
                    textDecoration="underline"
                    _hover={{ color: 'black', textDecoration: 'none' }}
                  >
                    {' '}
                    {fmText.formatMessage({ id: 'text.signin.label.forgotPassword' })}
                  </Link>
                </Box>

                <Button
                  backgroundColor="#000"
                  _hover={isFormInvalid() ? { backgroundColor: '#595959' } : { backgroundColor: '#000' }}
                  isLoading={isLoading}
                  fontSize=".875rem"
                  border="1px"
                  borderRadius="1"
                  opacity={isFormInvalid() ? 0.65 : 1}
                  isDisabled={isFormInvalid()}
                  type="submit"
                  height={'45px'}
                  _disabled={{ opacity: '0.65' }}
                >
                  {fmText.formatMessage({ id: 'button.signin.signinText' })}
                </Button>
                <Text fontSize=".875rem" mt={1} alignSelf="center">
                  {fmText.formatMessage({ id: 'link.signin.text.signup' })}
                  <Link
                    onClick={() => {
                      setShowRegisterModalFN(true)
                    }}
                    color="black"
                    textDecoration="underline"
                    _hover={{ color: 'black', textDecoration: 'none' }}
                    href="#"
                  >
                    &nbsp; {fmText.formatMessage({ id: 'link.signin.text.signupText' })}
                  </Link>
                </Text>

                <Box my={8} display="flex" color="#cacaca" alignItems="center">
                  <Divider size="8" flex="1" borderBottomWidth={'2px'} />
                  <Text px="2" color="#cacaca">
                    or
                  </Text>
                  <Divider flex="1" borderBottomWidth={'2px'} />
                </Box>
                {isOpen && <GoogleSignInSignUp buttonText="signin_with" handleLogin={handleGoogleSignInSignup} />}
              </VStack>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AtgLoginPage
