import { handleClientScriptLoad } from 'next/script'
import {
  ATG_BACKEND_ENDPOINT_DOMAIN,
  EPSILON_SCRIPT_URL,
  TARGETING_COOKIE_CATEGORY,
} from 'composable/components/general/constants'
import { appendCommonScript, getScriptType } from './appendScript'

interface EpsilonConfig {
  dtm_fid: number
  dtm_promo_id: number
  dtm_user_id?: string | null
  dtm_email_hash?: string | null
  dtmc_loc?: string
  cachebuster?: string
}

export const appendEpsilonScript = (newConfig: EpsilonConfig, id: string) => {
  let epsilonConfig: EpsilonConfig = {
    dtm_fid: 0,
    dtm_promo_id: 0,
    dtm_user_id: null,
    dtm_email_hash: null,
    dtmc_loc: window.location.href,
    cachebuster: new Date().getTime().toString(),
  }

  epsilonConfig = { ...epsilonConfig, ...newConfig }
  const scriptSrcVal = generateEpsilonScriptSrc(epsilonConfig, EPSILON_SCRIPT_URL)
  let element: HTMLScriptElement = document.querySelector('[itemId="epsiloncommonscript"]')
  if (element) {
    element.remove()
    appendCommonScript(scriptSrcVal, id, TARGETING_COOKIE_CATEGORY, null, 'epsiloncommonscript')
  } else {
    handleClientScriptLoad({
      src: scriptSrcVal,
      id,
      itemID: 'epsiloncommonscript',
      className: TARGETING_COOKIE_CATEGORY,
      type: getScriptType(TARGETING_COOKIE_CATEGORY),
    })
  }
}

export const removeScriptById = (scriptId: string): void => {
  const scriptElement = document.getElementById(scriptId)
  if (scriptElement) {
    document.head.removeChild(scriptElement)
  }
}

const generateEpsilonScriptSrc = (epsilonConfig: EpsilonConfig, epsilonScriptUrl: string): string => {
  const queryParams = Object.entries(epsilonConfig)
    .map(([key, value]) => {
      if (value !== null && value !== undefined) {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`
      }
      return null
    })
    .filter((param) => param !== null)
    .join('&')

  return `${ATG_BACKEND_ENDPOINT_DOMAIN}${epsilonScriptUrl}${queryParams}`
}
