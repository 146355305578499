import { birthMonths, states } from '../constants/account'

export const birthdayArray = Object.entries(birthMonths)
  .map(([birthMonth, birthdayValue]) => ({
    label: birthdayValue,
    value: birthMonth,
  }))
  .sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10))

export const statePickerItems = Object.entries(states)
  .map(([stateCode, stateName]) => ({
    label: stateName,
    value: stateCode,
  }))
  .sort((a, b) => a.label.localeCompare(b.label))
