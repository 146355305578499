import {
  Checkbox,
  CheckboxProps,
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  Text,
  TextProps,
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'

interface CheckboxFieldProps {
  checkboxProps: CheckboxProps
  content: string
  rootProps?: FormControlProps
  callToAction?: JSX.Element
  displayLabel?: boolean
  error?: FieldError
  formLabelProps?: FormLabelProps
  isRequired?: boolean
  label?: string
  caption?: string
  contentStyle?: TextProps
}

export const CheckboxField = ({
  rootProps,
  checkboxProps,
  content,
  callToAction,
  error,
  formLabelProps,
  displayLabel = false,
  isRequired = false,
  label = '',
  caption,
  contentStyle,
}: CheckboxFieldProps) => {
  const { name } = checkboxProps
  if (!name) {
    return null
  }

  return (
    <FormControl isInvalid={Boolean(error)} isRequired={isRequired} {...rootProps}>
      {displayLabel && (
        <Flex justify="space-between">
          <FormLabel
            height={{ base: '14px', md: '17px' }}
            fontWeight="700"
            textStyle={'callouts-blockquote-75'}
            {...formLabelProps}
          >
            {label}
          </FormLabel>
          {callToAction}
        </Flex>
      )}
      <Checkbox height={{ base: '1rem', md: '1.5rem' }} {...checkboxProps}>
        <Text textStyle={'callouts-blockquote-75'} as="span" {...contentStyle}>
          {content}
          {isRequired && (
            <Text as="span" color={'danger-med'} fontSize={'1rem'}>
              &nbsp;*
            </Text>
          )}
        </Text>
      </Checkbox>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
      {caption && <FormHelperText fontSize="xs">{caption}</FormHelperText>}
    </FormControl>
  )
}
