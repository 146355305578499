import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Divider,
  ModalCloseButton,
  Box,
  VStack,
  FormControl,
  Input,
  Text,
  Button,
  FormLabel,
  FormErrorMessage,
  Link,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useFormat } from 'helpers/hooks/useFormat'
import atgAxiosInstance from 'helpers/axios-atg-helper'
import { core } from 'composable/chakra/figma-tokens'

const AtgForgotPassword = ({ isOpen, onClose }) => {
  const fmText = useFormat({ name: 'common' })
  const [isLoading, setIsLoading] = useState(false)
  const [htmlErrorContent, setErrorHtmlContent] = useState()
  const [emailVal, setEmailVal] = useState('')
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
  } = useForm()

  const onSubmit = async (form: any) => {
    setIsLoading(true)
    setErrorHtmlContent(null)

    try {
      const res = await atgAxiosInstance.post('currentUser/forgotPassword', {
        emailAddress: form.email,
      })
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      setErrorHtmlContent(e.response?.data?.title || e.message)
      console.log(e)
    }
  }

  const handleChange = (e) => {
    setEmailVal(e.target.value)
  }

  const closeAndReset = () => {
    reset()
    setEmailVal('')
    onClose()
  }
  return (
    <Modal isOpen={isOpen} onClose={closeAndReset}>
      <ModalOverlay />
      <ModalContent mx={2} my={4} borderRadius="4px" borderColor="black" width={'34.5rem !important'}>
        <ModalHeader fontSize="1.25rem" fontWeight="400" lineHeight="1.2" textAlign="center">
          {fmText.formatMessage({ id: 'text.forgotPassword.heading' })}
        </ModalHeader>
        <ModalCloseButton size={'lg'} _hover={{ backgroundColor: 'none !important', opacity: '0.7' }} />
        <Box px={5}>
          <Divider borderColor="gray.800" />
        </Box>
        <ModalBody>
          {htmlErrorContent && (
            <Box
              p={4}
              color="#721c24"
              textColor="red"
              bgColor="#f8d7da"
              borderColor="#f5c6cb"
              dangerouslySetInnerHTML={{ __html: htmlErrorContent }}
            />
          )}
          <Box>
            <Box py={3}>
              <Text mb={2} fontSize=".875rem" fontFamily="Swiss-721-Cn, Helvetica, Arial, sans-serif" fontWeight="400">
                {fmText.formatMessage({ id: 'text.forgotPassword.headingText' })}
              </Text>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing={8} align="stretch">
                <FormControl variant={'floating'} isInvalid={!isValid}>
                  <Input
                    border="1px"
                    borderRadius="1"
                    placeholder=" "
                    autoFocus
                    type="email"
                    height={'45px'}
                    mb={2}
                    {...register('email', {
                      required: 'Email is required',
                      onChange: handleChange,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: fmText.formatMessage({ id: 'error.forgotPassword.invalidEmailText' }),
                      },
                    })}
                  />
                  <FormLabel fontSize=".875rem">{fmText.formatMessage({ id: 'text.signin.label.email' })}</FormLabel>
                  {emailVal && (
                    <FormErrorMessage>
                      {fmText.formatMessage({ id: 'error.forgotPassword.invalidEmailText' })}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <Button
                  colorScheme="black"
                  isLoading={isLoading}
                  fontSize=".875rem"
                  border="1px"
                  isDisabled={!isValid}
                  borderRadius="0"
                  type="submit"
                  _disabled={{ backgroundColor: core.primary['primary-900'], opacity: 0.7 }}
                  _hover={{ opacity: 0.5 }}
                >
                  {fmText.formatMessage({ id: 'button.text.emailPassword' })}
                </Button>
                <Link
                  _hover={{ color: 'black', textDecoration: 'none' }}
                  textDecoration="underline"
                  textAlign={'center'}
                  onClick={closeAndReset}
                  mb={5}
                >
                  {fmText.formatMessage({ id: 'link.text.cancel' })}
                </Link>
              </VStack>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AtgForgotPassword
