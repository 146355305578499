import { FC, useEffect, useState } from 'react'
import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react'
import SignUpForm, { SignUpFormFields } from 'composable/components/account/signup-form'
import { CheckoutAddressType } from 'composable/helpers/utils/experian-address-utils'
import atgAxiosInstance from 'helpers/axios-atg-helper'
import { addCAPIFbEvent, getCAPIrootEventsProps } from 'helpers/metaCapiHelper'
import { appendEpsilonScript } from 'helpers/utils/epsilonScript'
import { sha256 } from 'js-sha256'
import {
  AddressDataType,
  AddrLineItem,
  CreateAccountTypes,
  PickListItem,
  qasAddressParams,
  recommendedAddress,
} from 'types/Account'
import { useAtgUser } from 'frontastic/contexts'
import { QasModal } from './QasModal'

interface Props {
  isOpen: boolean
  openSignInModal: () => void
  onOpen?: () => void
  onClose?: () => void
  callBack?: Function
}

export enum VerifyLevel {
  STREETPARTIAL = 'StreetPartial',
  NONE = 'None',
  VERIFIED = 'Verified',
  INTERACTIONREQUIRED = 'InteractionRequired',
  MULTIPLE = 'Multiple',
  PREMISEPARTIAL = 'PremisesPartial',
}

const SignUpModal: FC<Props> = ({ isOpen, openSignInModal, onClose, onOpen, callBack }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [user, setUser] = useState<any>({})
  const [qasModalopen, setQASModalopen] = useState(false)
  const [recommendedAddress, setRecommendedAddress] = useState<PickListItem[]>([])
  const [enteredAddress, setEnteredAddress] = useState<CheckoutAddressType>()
  const [formState, setFormState] = useState<SignUpFormFields>()
  const [verifyLevel, setVerifyLevel] = useState<VerifyLevel>()
  const [addressChanged, setAddressChanged] = useState(false)
  const [verifiedAddress, setVerifiedAddress] = useState<PickListItem[]>([])
  const { userSessionData } = useAtgUser()
  const handleAddressChange = (val: boolean) => {
    setAddressChanged(val)
  }

  const handleSetFormState = (form: SignUpFormFields) => {
    setFormState(form)
  }

  const handleSignInModalToggle = () => {
    onClose?.()
    openSignInModal()
  }

  const scrollToError = () => {
    const errorElement = document.getElementById('#error')
    errorElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }

  const handleRegister = async (values: SignUpFormFields) => {
    setIsLoading(true)
    const {
      address,
      city,
      state,
      zipcode,
      address2,
      birthMonth,
      confirmPassword,
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
      country,
      eventsEmail,
      eventsUSMail,
      rewardsClubEmails,
    } = values
    const searchString = `${address}||${city}||${state}||${zipcode}`
    const params = {
      country,
      addLayout: 'Cmale2',
      searchString,
      promptSet: 'Default',
    }
    try {
      const res = await atgAxiosInstance.post('qas/adderessSearch', params)
      const data = res.data
      const resultData = {
        verifylevel: data.verifylevel,
        address: data.addrLineItem,
      }

      const createAccountParams = {
        profile: {
          email,
          firstName,
          lastName,
          birthMonth,
        },
        credentials: {
          userId: email,
          password,
          confirmPassword,
        },
        addressList: {
          firstName,
          lastName,
          addressLine1: address,
          addressLine2: address2,
          city,
          state,
          postalCode: zipcode,
          phone: phoneNumber,
          addressType: getAddressType(values),
          addressValidated: resultData.verifylevel === 'Verified' && isValidZipCode(zipcode),
          useAddrAsEntered: false,
        },
        communicationPreferences: {
          dxlMail: eventsEmail,
          dxlEmail: eventsUSMail,
          rewardsProgram: rewardsClubEmails,
          cmMail: false,
          rcMail: false,
          lxlMail: false,
          lxlEmail: false,
          cmEmail: false,
          rcEmail: false,
        },
      }

      const response = await atgAxiosInstance.post('currentUser/create', removeEmptyProperties(createAccountParams))
      const result = response.data
      setUser(result)
      onClose?.()
      const eventData = {
        events: [
          {
            ...getCAPIrootEventsProps('CompleteRegistration'),
            customer_information: [
              {
                key: 'country',
                value: userSessionData?.dxlCountry,
              },
            ],
          },
        ],
        partner_agent: 'liveramp',
      }
      addCAPIFbEvent(eventData)
      if (typeof callBack === 'function') {
        callBack(result)
        window.dispatchEvent(new Event('userLoggedIn'))
      } else {
        const url = '/myaccount/rewards/benefits'
        window.location.href = url
      }
    } catch (error) {
      setError(error?.response?.data?.title)
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const isValidZipCode = (zipcode) => {
    var regex = /^\d{5}-\d{4}$/
    return regex.test(zipcode)
  }

  const getAddressType = (address: any) => {
    let formType = 'US'
    if (address && address.address && address.city && address.state) {
      const city = address.city.toLowerCase()
      const state = address.state
      let addressLine1 = address.address.replace(/[^A-Z0-9]+/gi, '')
      addressLine1 = addressLine1.toLowerCase()
      let addressLine2 = address.address2.replace(/[^A-Z0-9]+/gi, '')
      addressLine2 = addressLine2.toLowerCase()

      if (
        (city === 'apo' || city === 'fpo' || city === 'dpo') &&
        (state === 'AA' || state === 'AP' || state === 'AE')
      ) {
        formType = 'APO/FPO/DPO'
      } else if (addressLine1.indexOf('pobox') !== -1 || addressLine2.indexOf('pobox') !== -1) {
        formType = 'POBox'
      } else {
        formType = 'US'
      }
    }

    return formType
  }

  const mapCreateAccountObj = (values: SignUpFormFields, isAddressValidated: boolean = false): CreateAccountTypes => {
    const {
      address,
      city,
      state,
      zipcode,
      address2,
      birthMonth,
      confirmPassword,
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
      country,
      eventsEmail,
      eventsUSMail,
      rewardsClubEmails,
    } = values
    return {
      profile: {
        email,
        firstName,
        lastName,
        birthMonth,
      },
      credentials: {
        userId: email,
        password,
        confirmPassword,
      },
      addressList: {
        firstName,
        lastName,
        addressLine1: address,
        addressLine2: address2,
        city,
        state,
        postalCode: zipcode,
        phone: phoneNumber,
        addressType: getAddressType(values),
        addressValidated: isAddressValidated,
        useAddrAsEntered: false,
      },
      communicationPreferences: {
        dxlMail: eventsEmail,
        dxlEmail: eventsUSMail,
        rewardsProgram: rewardsClubEmails,
        cmMail: false,
        rcMail: false,
        lxlMail: false,
        lxlEmail: false,
        cmEmail: false,
        rcEmail: false,
      },
    }
  }

  const openQasModal = () => {
    setQASModalopen(true)
  }

  const modalOnClose = () => {
    setQASModalopen(false)
    onClose?.()
  }

  const setFormValues = (values?: recommendedAddress) => {
    let modifieldAddress = {
      ...formState,
    }
    if (values?.streetNumber) {
      modifieldAddress.address2 = values?.streetNumber
        ? `${values?.streetNumber} ${formState.address2}`
        : formState.address2
    } else if (values) {
      const { address, address2, postCode, region, state } = values
      modifieldAddress.address = address
      modifieldAddress.address2 = address2
      modifieldAddress.city = region
      modifieldAddress.state = state
      modifieldAddress.zipcode = postCode
      onSubmit(modifieldAddress)
    }
    handleSetFormState(modifieldAddress)
    onClose?.()
    modalOnClose()
  }

  const mapFormObject = (form: SignUpFormFields) => ({
    additional_address_info: form.address,
    additional_street_info: form.address2,
    city: form.city,
    country: form.country,
    first_name: form.firstName,
    id: '',
    last_name: form.lastName,
    phone_number: form.phoneNumber,
    postcode: form.zipcode,
    state: form.state,
    street_name: form.address,
    street_number: '',
  })

  const fetchQasAddressSearch = async (params: qasAddressParams) => {
    try {
      const res = await atgAxiosInstance.post('qas/adderessSearch', params)
      const data: AddressDataType = res.data
      const displayVerifiedAddress = data.pickListItems?.filter((item) => item.fulladdress === true)
      return {
        rawResponse: data,
        addressList: displayVerifiedAddress,
        address: data.addrLineItem,
        verifyLevel: data.verifylevel,
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleQasModal = async (
    qasAddress: {
      rawQASRes?: AddressDataType
      addressList: PickListItem[]
      address: AddrLineItem
      verifyLevel: VerifyLevel
    },
    form: SignUpFormFields,
  ) => {
    setIsLoading(false)
    setVerifyLevel(qasAddress?.verifyLevel)
    if (
      qasAddress?.verifyLevel === VerifyLevel.NONE ||
      qasAddress?.verifyLevel === VerifyLevel.STREETPARTIAL ||
      qasAddress?.verifyLevel === VerifyLevel.INTERACTIONREQUIRED ||
      qasAddress?.verifyLevel === VerifyLevel.MULTIPLE ||
      qasAddress?.verifyLevel === VerifyLevel.PREMISEPARTIAL
    ) {
      const enteredAddress: CheckoutAddressType = mapFormObject(form)

      setRecommendedAddress(qasAddress?.addressList)
      setEnteredAddress(enteredAddress)
      handleSetFormState(form)
      handleModalClose()
      openQasModal()
    }
  }

  const handleRegistrations = async (params: qasAddressParams, form: SignUpFormFields) => {
    const qasRes = await fetchQasAddressSearch(params)

    if (qasRes?.verifyLevel === VerifyLevel.VERIFIED) {
      return qasRes
    } else {
      handleQasModal(qasRes, form)
    }
  }

  const handleCreateAccount = async (createAccountParams: CreateAccountTypes) => {
    try {
      setIsLoading(true)
      const response = await atgAxiosInstance.post('currentUser/create', removeEmptyProperties(createAccountParams))
      const result = response.data
      setUser(result)
      onClose?.()
      const eventData = {
        events: [
          {
            ...getCAPIrootEventsProps('CompleteRegistration'),
            customer_information: [
              {
                key: 'country',
                value: userSessionData?.dxlCountry,
              },
            ],
          },
        ],
        partner_agent: 'liveramp',
      }
      addCAPIFbEvent(eventData)
      const dtm_config = {
        dtm_fid: 5419,
        dtm_promo_id: 941,
        dtm_email_hash: sha256.hex(createAccountParams.profile.email),
        dtmc_loc: window.location.href,
        cachebuster: new Date().getTime() + '',
      }
      appendEpsilonScript(dtm_config, 'epsilonRegister')
      const url = '/myaccount/rewards/benefits'
      window.location.href = url
    } catch (error) {
      setError(error?.response?.data?.title)
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleOnSubmitRegistration = async (form: SignUpFormFields, hasPassedQAS?: boolean) => {
    setIsLoading(true)
    const { address, city, state, zipcode, country } = form
    const searchString = `${address}||${city}||${state}||${zipcode}`
    const params = {
      country,
      addLayout: 'Cmale2',
      searchString,
      promptSet: 'Default',
    }
    if (!hasPassedQAS) {
      const qasData = await handleRegistrations(params, form)

      if (qasData?.verifyLevel === VerifyLevel.VERIFIED) {
        const createAccountParams = mapCreateAccountObj(form, true)
        const obj = { ...createAccountParams }
        obj.addressList.addressLine1 = qasData?.rawResponse?.addrLineItem?.addressLineOne
        obj.addressList.addressLine2 = qasData?.rawResponse?.addrLineItem?.addressLineTwo
        obj.addressList.city = qasData?.rawResponse?.addrLineItem?.city
        obj.addressList.state = qasData?.rawResponse?.addrLineItem?.state
        obj.addressList.postalCode = qasData?.rawResponse?.addrLineItem?.zip
        obj.addressList.addressValidated = true
        obj.addressList.useAddrAsEntered = false
        obj.addressList.addressType = getAddressType({
          address: qasData?.rawResponse?.addrLineItem?.addressLineOne,
          address2: qasData?.rawResponse?.addrLineItem?.addressLineTwo,
          city: qasData?.rawResponse?.addrLineItem?.city,
          state: qasData?.rawResponse?.addrLineItem?.state,
        })
        await handleCreateAccount(createAccountParams)
      }
    } else {
      const createAccountParams = mapCreateAccountObj(form, true)
      await handleCreateAccount(createAccountParams)
    }
  }

  const handleAddressFromQAS = (selectedAddress: AddrLineItem, isAddressVerified: boolean, useAsEntered: boolean) => {
    const createAccountParams = mapCreateAccountObj(formState, isAddressVerified)
    const obj = { ...createAccountParams }
    obj.addressList.addressLine1 = selectedAddress?.addressLineOne
    obj.addressList.addressLine2 = selectedAddress?.addressLineTwo
    obj.addressList.city = selectedAddress?.city
    obj.addressList.state = selectedAddress?.state
    obj.addressList.postalCode = selectedAddress?.zip
    obj.addressList.addressValidated = isAddressVerified
    obj.addressList.useAddrAsEntered = useAsEntered
    obj.addressList.addressType = getAddressType({
      address: selectedAddress?.addressLineOne,
      address2: selectedAddress?.addressLineTwo,
      city: selectedAddress?.city,
      state: selectedAddress?.state,
    })

    modalOnClose()
    handleCreateAccount(obj)
  }

  const removeEmptyProperties = (obj) => {
    const newObj = {}
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        if (typeof obj[key] === 'object') {
          const nestedObj = removeEmptyProperties(obj[key])
          if (Object.keys(nestedObj).length !== 0) {
            newObj[key] = nestedObj
          }
        } else {
          newObj[key] = obj[key]
        }
      }
    }
    return newObj
  }

  const onSubmit = (data: SignUpFormFields) => {
    handleOnSubmitRegistration(data)
  }

  useEffect(() => {
    let timer
    if (error) {
      scrollToError()
      timer = setTimeout(() => {
        setError('')
      }, 10000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [error])

  useEffect(() => {
    if (!isOpen) {
      setError('')
    }
  }, [isOpen])

  const handleModalClose = () => {
    onClose?.()
  }

  return (
    <Box>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleModalClose} size={'sm'}>
        <ModalOverlay />
        <ModalContent mx={2} my={4}>
          <ModalCloseButton
            zIndex={11111}
            size={'lg'}
            _hover={{ backgroundColor: 'none !important', opacity: '0.7' }}
          />
          <ModalBody padding={'unset'} margin={'unset'} position="relative">
            {isLoading && (
              <Box
                position="fixed"
                display="flex"
                justifyContent="center"
                alignItems="center"
                zIndex={9999}
                top={0}
                left={0}
                width="100%"
                height="100%"
                background={'rgba(0, 0, 0, 0.5)'}
              >
                <Spinner size="xl" />
              </Box>
            )}
            <SignUpForm
              error={error}
              handleSignInModalToggle={handleSignInModalToggle}
              handleModalToggle={handleModalClose}
              onSubmit={onSubmit}
              formData={formState}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <QasModal
        modalOpen={qasModalopen}
        modalOnClose={modalOnClose}
        enteredAddress={enteredAddress}
        recommendedAddress={recommendedAddress}
        setFormValues={setFormValues}
        handleAddressFromQAS={handleAddressFromQAS}
        verifyLabel={verifyLevel}
      />
    </Box>
  )
}

export default SignUpModal
