import { Icon, IconProps } from '@chakra-ui/react'

export const RepeatIcon = (props: IconProps) => (
  <Icon
    id="icon-repear"
    aria-label="Repeat Icon"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="currentColor"
    stroke="currentColor"
    viewBox="0 0 39 32"
    strokeWidth="0"
    {...props}
  >
    <path d="M35.298 16.002c0-8.837-7.022-16.002-15.707-16.002-5.627 0.029-10.546 3.034-13.265 7.521l-0.039 0.069 1.386 1.543c2.355-4.261 6.811-7.105 11.933-7.133l0.004-0c7.581 0 13.725 6.269 13.725 14.002h-3.922l4.901 5.497 4.906-5.497zM19.592 30c-7.576 0-13.725-6.269-13.725-13.997h3.941l-4.901-5.502-4.906 5.502h3.922c0 8.833 7.026 15.998 15.707 15.998 5.634-0.022 10.562-3.027 13.289-7.516l0.039-0.069-1.386-1.543c-2.358 4.263-6.82 7.107-11.948 7.128h-0.003z" />
  </Icon>
)
