import {
  Box,
  Button,
  Center,
  HStack,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { CheckoutAddressType } from 'composable/helpers/utils/experian-address-utils'
import { VerifyLevel } from 'frontastic/tastics/SignupModal'
import atgAxiosInstance from 'helpers/axios-atg-helper'
import { useFormat } from 'helpers/hooks/useFormat'
import { useState } from 'react'
import { AddrLineItem, PickListItem, recommendedAddress } from 'types/Account'

interface QasModalProps {
  modalOpen: boolean
  modalOnClose: () => void
  enteredAddress?: CheckoutAddressType
  recommendedAddress?: PickListItem[]
  setFormValues: (values?: recommendedAddress, streetNumber?: string) => void
  handleAddressFromQAS: (addressRetuned: AddrLineItem, verifiedAddress: boolean, useAsEntered: boolean) => void
  verifyLabel: VerifyLevel
}

export const QasModal = ({
  modalOpen,
  modalOnClose,
  enteredAddress,
  recommendedAddress,
  setFormValues,
  handleAddressFromQAS,
  verifyLabel,
}: QasModalProps) => {
  const { formatMessage } = useFormat({ name: 'common' })

  const [selectedAddress, setSelectedAddress] = useState('recommended')
  const [aptStreetNumber, setAptStreetNumber] = useState<string>('')

  const handleAptStreetChange = (e: any) => {
    setAptStreetNumber(e.target.value)
  }

  const handleStreetAptSubmit = () => {
    if (aptStreetNumber?.length) {
      setFormValues({ address: '', region: '', state: '', postCode: '', streetNumber: aptStreetNumber })
      setAptStreetNumber('')
    }
  }

  const handleSelectedAddress = (selectedAddress: PickListItem) => async () => {
    const [address, regionState] = selectedAddress?.addresstext?.split(',')
    const [region, state] = regionState?.trim()?.split(' ')

    if (selectedAddress?.apartment) {
      setFormValues({ address, region, state, postCode: selectedAddress.postcode })
    } else {
      const refineReq = {
        country: 'USA',
        addLayout: 'Cmale2',
        moniker: selectedAddress.moniker,
      }

      const res = await atgAxiosInstance.post('qas/adderessRefine', refineReq)
      if (res.data?.verifylevel == 'Verified') {
        handleAddressFromQAS(res.data?.addrLineItem, true, false)
      }
    }
  }

  const handleFormEdit = () => {
    setFormValues()
  }

  const convertToLineItemAddress = (address: CheckoutAddressType) => {
    const addr: AddrLineItem = {
      addressLineOne: address.street_name,
      addressLineTwo: address.additional_address_info,
      city: address.city,
      state: address.state,
      zip: address.postcode,
    }
    return addr
  }

  const renderVerifiedAddress = () => {
    return (
      <Box maxHeight="200px" overflowY="scroll">
        {recommendedAddress?.map((address) => (
          <Text
            p={1}
            textDecoration={'underline'}
            _hover={{ backgroundColor: 'gray.200', textDecoration: 'unset' }}
            key={address?.postcode}
            onClick={handleSelectedAddress(address)}
          >
            {address?.addresstext}
            {address?.postcode}
          </Text>
        ))}
      </Box>
    )
  }

  return (
    <Modal
      isOpen={modalOpen}
      onClose={modalOnClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size={{ base: 'xs', lg: 'sm' }}
    >
      <ModalOverlay />
      <ModalContent mx={{ base: 16, lg: 0 }}>
        <VStack width="100%" spacing={0}>
          <Box width="100%" p={2}>
            <Center py={1}>
              <Text textStyle={'body-200'} textAlign="center">
                {formatMessage({
                  id: 'addressConfirmation.modal.title',
                })}
              </Text>
              <ModalCloseButton />
            </Center>
          </Box>

          <VStack width="100%" py={4} px={{ base: 4, lg: 6 }} spacing={4}>
            <Text width="100%" textColor={'text.danger'} textStyle={'heading-desktop-75'} textAlign="left">
              {formatMessage({
                id: 'addressConfirmation.modal.message',
              })}
            </Text>
            <Stack width="100%" direction={{ base: 'column', lg: 'row' }}>
              <HStack
                p={4}
                alignItems="flex-start"
                borderRadius="base"
                cursor="pointer"
                borderColor={selectedAddress === 'youEntered' ? 'primary.text' : 'surface.border'}
                onClick={() => setSelectedAddress('youEntered')}
                flex={1}
                borderRight={{ lg: '2px dotted' }}
              >
                <VStack flex={1} alignItems="flex-start">
                  <HStack>
                    <Text textStyle={'heading-desktop-75'}>
                      {formatMessage({
                        id: 'addressConfirmation.modal.entered',
                      })}
                    </Text>
                    <Text textColor={'text.danger'}>*</Text>
                  </HStack>
                  <Box width="100%" py={1} borderBottom="1px solid" borderBottomColor="surface.border" />
                  <Box>
                    <Text>{enteredAddress?.street_name}</Text>
                    {enteredAddress?.additional_street_info ?? <Text>{enteredAddress?.additional_street_info}</Text>}
                    <Text>{enteredAddress?.city}</Text>
                    <Text>
                      {enteredAddress?.state}, {enteredAddress?.postcode}
                    </Text>
                  </Box>

                  <VStack width="100%" py={4}>
                    <Button
                      variant="solid"
                      size={{ base: 'sm', lg: 'lg' }}
                      textStyle={{ base: 'ui-button-75', lg: 'ui-button-200' }}
                      py={4}
                      px={{ base: 4, lg: 6 }}
                      flex={1}
                      onClick={handleFormEdit}
                      width={'full'}
                      borderRadius={0}
                    >
                      {formatMessage({
                        id: 'addressConfirmation.modal.button.edit',
                      })}
                    </Button>
                    <Button
                      variant="outline"
                      size={{ base: 'sm', lg: 'lg' }}
                      textStyle={{ base: 'ui-button-75', lg: 'ui-button-200' }}
                      py={4}
                      px={{ base: 4, lg: 6 }}
                      flex={2}
                      onClick={() => {
                        handleAddressFromQAS(convertToLineItemAddress(enteredAddress), false, true)
                      }}
                      width={'full'}
                      borderRadius={0}
                    >
                      {formatMessage({
                        id: 'addressConfirmation.modal.button.useSelected',
                      })}
                    </Button>
                    <HStack>
                      <Text color={'text.danger'}>*</Text>
                      <Text textAlign={'left'} color={'text.primary-inverse'} textStyle={'body-50'}>
                        {formatMessage({
                          id: 'addressConfirmation.modal.message.undelivered',
                        })}
                      </Text>
                    </HStack>
                  </VStack>
                </VStack>
              </HStack>
              <HStack
                p={4}
                alignItems="flex-start"
                cursor="pointer"
                borderRadius="base"
                borderColor={selectedAddress === 'recommended' ? 'primary.text' : 'surface.border'}
                onClick={() => setSelectedAddress('recommended')}
                flex={1}
              >
                <VStack flex={1} alignItems="flex-start" width={'full'}>
                  <Text textStyle={'heading-desktop-75'}>
                    {formatMessage({
                      id: 'addressConfirmation.modal.chooseVerified',
                    })}
                  </Text>
                  <Box width="100%" p={2} borderBottom="1px solid" borderBottomColor="surface.border" />
                  {(verifyLabel === VerifyLevel.STREETPARTIAL ||
                    verifyLabel === VerifyLevel.INTERACTIONREQUIRED ||
                    verifyLabel === VerifyLevel.PREMISEPARTIAL ||
                    verifyLabel === VerifyLevel.MULTIPLE) && (
                    <VStack width={'full'}>
                      <Input
                        value={aptStreetNumber}
                        placeholder={formatMessage({
                          id: 'addressConfirmation.modal.input.placeholder',
                          values: { addressLineType: verifyLabel == 'PremisesPartial' ? 'Apartment' : 'Street' },
                        })}
                        variant={'unstyled'}
                        borderRadius={0}
                        paddingX={5}
                        fontSize={14}
                        _focus={{ borderColor: 'text.primary', borderWidth: 2 }}
                        onChange={handleAptStreetChange}
                      />
                      <Box paddingBottom={2} />
                      <Button
                        variant="solid"
                        size={{ base: 'sm', lg: 'lg' }}
                        textStyle={{ base: 'ui-button-75', lg: 'ui-button-200' }}
                        py={4}
                        px={{ base: 4, lg: 6 }}
                        flex={1}
                        onClick={handleStreetAptSubmit}
                        width={'full'}
                        borderRadius={0}
                      >
                        {formatMessage({
                          id: 'addressConfirmation.modal.button.confirm',
                        })}
                      </Button>
                    </VStack>
                  )}
                  {recommendedAddress && recommendedAddress?.length > 0 && (
                    <VStack>
                      <Text textStyle={'body-50'} color={'text.shaded'}>
                        {formatMessage({
                          id: 'addressConfirmation.modal.VerifiedAddress',
                        })}
                      </Text>
                      <Text>{renderVerifiedAddress()}</Text>
                    </VStack>
                  )}
                  {verifyLabel === VerifyLevel.NONE && (
                    <Text textStyle={'heading-desktop-75'} color={'text.danger'} paddingY={5}>
                      {formatMessage({
                        id: 'addressConfirmation.modal.chooseVerified.message',
                      })}
                    </Text>
                  )}
                </VStack>
              </HStack>
            </Stack>
            <Box borderBottom={'1px solid'} width={'full'} />
          </VStack>
        </VStack>
      </ModalContent>
    </Modal>
  )
}
